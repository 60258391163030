import $ from 'jquery';
import React, { FC, useEffect } from 'react';

import {
  LpMainTtlPc,
  LpConceptIllust,
  LpConceptPhoto1,
  LpConceptPhoto2,
  LpConceptPhoto3,
  LpConceptPhoto4,
  LpConceptPhoto5,
  LpAboutSearch,
  LpAboutShopping,
  LpAboutPresent,
  LpAboutTrend,
  LpCtaLineL,
  LpPointPhoto01,
  LpPointNum1,
  LpPointNum2,
  LpPointPhoto03,
  LpPointPhoto02,
  LpPointNum3,
  LpCtaLineR,
  LpCtaPhoto01,
  LpCtaPhoto05,
  LpCtaPhoto02,
  LpCtaPhoto03,
  LpCtaPhoto04,
  LpShopKonosore,
  LpShopSaitaya,
  LpShopTougane,
  LpVoicePhoto40sWoman,
  LpVoicePhoto20sWoman,
  LpVoicePhoto30sMan,
  LpVoicePhoto30sWoman,
} from '@assets/image/png';
import {
  IllustDocumentFile,
  IllustPeopleFile,
  IllustSearchFile,
  IllustTrackFile,
} from '@assets/image/svg/lp';
import SvgIllustTime from '@assets/image/svg/lp/delivery/IllustTime';
// import SvgIllustPeople from '@assets/image/svg/lp/security/IllustPeople';
import SvgCheck from '@assets/image/svg/lp/shop/Check';

export const ForBeginner: FC = () => {
  useEffect(() => {
    $('.voice-list').slick({
      slidesToShow: 2,
      centerPadding: '0',
      speed: 600,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      nextArrow: '<button type="button" class="slick-next" aria-label="次のスライドへ"></button>',
      prevArrow: '<button type="button" class="slick-prev" aria-label="前のスライドへ"></button>',
      pauseOnFocus: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '15%',
          },
        },
      ],
    });
  }, []);

  return (
    <div id="lp-main">
      <div className="lp-fv lp-fade">
        <div className="lp-content">
          <div className="lp-fv-title">
            <img src={LpMainTtlPc} alt="ようこそ、Tastyモールへ！" className="pc" />
            <p>
              ようこそ、Tastyモールへ！
              <br />
              Tastyモールは、 一人一人の<strong>食のこだわり</strong>
              に応えるためのパーソナライズフードマーケットです。
              <br />
              自分好みの<strong>こだわりの食品</strong>
              に出会える、新感覚のお買い物体験をお楽しみください。
            </p>
            <a className="lp-cta-btn" href="https://tasty-mall.com/pre-signup">
              無料で新規会員登録
            </a>
          </div>
        </div>
      </div>
      <section className="lp-section lp-concept lp-fade">
        <div className="lp-content">
          <dl>
            <dt className="pc">
              <img src={LpConceptIllust} alt="Tastyモール" />
            </dt>
            <dd>
              <h2>
                <span className="style-h2-large">こだわりの食品</span>
                <span className="style-h2">
                  を通じて
                  <br />
                  <span className="orange">創り手</span>と<span className="orange">買い手</span>
                  をつなぐこと。
                </span>
              </h2>
              <img src={LpConceptIllust} alt="Tastyモール" className="sp" />
              <p className="style-h3">
                日々のお買い物で、こんな<span className="emphasis">お</span>
                <span className="emphasis">悩</span>
                <span className="emphasis">み</span>を感じたことありますか？
              </p>
              <ul>
                <li>
                  食品は世の中にたくさんあるけど、
                  <strong>どれが本当においしい食品なのかわからない</strong>
                </li>
                <li>
                  贈り物の定番商品はみんな食べたことがあるから<strong>無難なものを選びがち</strong>
                </li>
                <li>
                  食にこだわったライフスタイルは、<strong>自分で探すのが大変...</strong>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </section>
      <section className="lp-section lp-carousel lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center">
            毎日<span className="orange">食べたいものを食べる</span>こと、
            <br className="sp" />
            <span className="orange">新しい食品に出会う</span>こと。
          </h2>
        </div>
        <div className="scroll-infinity">
          <div className="scroll-infinity__wrap">
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto1} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto2} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto3} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto4} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto5} />
              </li>
            </ul>
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto1} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto2} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto3} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto4} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto5} />
              </li>
            </ul>
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto1} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto2} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto3} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto4} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpConceptPhoto5} />
              </li>
            </ul>
          </div>
          <div className="lp-content">
            <p className="lp-notice">※写真はイメージになります。</p>
          </div>
        </div>
        <div className="lp-content">
          <p className="style-h3 text-center">
            そんな日々の楽しみを、
            <br className="sp" />
            Tastyモールが提供します。
          </p>
          <a className="lp-cta-btn lp-cta-btn-center" href="https://tasty-mall.com/pre-signup">
            無料で新規会員登録
          </a>
        </div>
      </section>
      <section className="lp-section lp-about lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center">
            <span className="en orange">ABOUT</span>
            <br />
            Tastyモールは
            <br className="sp" />
            どんなときに使うの？
          </h2>
          <dl>
            <dt>
              <img src={LpAboutSearch} alt="" />
            </dt>
            <dd>
              <h3 className="style-h3">自分のこだわり条件で食品を探す</h3>
              <p>
                アレルギー対応を考えた商品検索や、オーガニック商品、ハラールなど、さまざまな条件で検索できます！
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={LpAboutShopping} alt="" />
            </dt>
            <dd>
              <h3 className="style-h3">日常使いの食品探しに使えるECサイト</h3>
              <p>こだわりの食品から日常的に使いやすい食品まで、幅広い商品を取り揃えています。</p>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={LpAboutPresent} alt="" />
            </dt>
            <dd>
              <h3 className="style-h3">大切な人へのギフト探し</h3>
              <p>
                お店では手に入らないような食品も取り扱っており、大切な方へのギフト探しにもぴったりです。
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={LpAboutTrend} alt="" />
            </dt>
            <dd>
              <h3 className="style-h3">食のトレンドコンテンツをいち早くキャッチ</h3>
              <p>
                Tastyモールのサイトを訪れると、最新のトレンドの食品をいち早く手に入れることができます！
              </p>
            </dd>
          </dl>
          <a className="lp-cta-btn lp-cta-btn-center" href="https://tasty-mall.com/pre-signup">
            無料で新規会員登録
          </a>
        </div>
      </section>
      <section className="lp-section lp-point lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center">
            <span className="en orange">POINT</span>
            <br />
            Tastyモールは
            <br />
            今までのショッピングサイトと何が違うの？
          </h2>
          <p className="style-h3 thin text-center lp-point-info">
            自分の<strong>こだわりに合った条件</strong>で欲しい食品がすぐに見つかる！
            <br />
            食品に特化した豊富な検索条件で、<strong>自分好みの食品</strong>が見つかります。
          </p>
          <dl>
            <dt>
              <img src={LpPointPhoto01} alt="" />
            </dt>
            <dd>
              <img src={LpPointNum1} alt="" />
              <h3 className="style-h2">自分にぴったりの食品をさがす</h3>
              <p>
                無添加、オーガニック、ヴィーガン、高たんぱく…
                自分のライフスタイルに合った食品だけを画面に表示させることができます。
                <br />
                Tastyモールなら便利で快適なあなただけの「こだわりフードマーケット」に早変わり。
              </p>
            </dd>
          </dl>
          <dl className="reverse">
            <dt>
              <img src={LpPointPhoto02} alt="" />
            </dt>
            <dd>
              <img src={LpPointNum2} alt="" />
              <h3 className="style-h2">「食べられる物」だけを探せる</h3>
              <p>
                アレルギーを持ってるから食べられるものが限定されている方や、思想や文化、健康上の理由を持つ方々も快適に「便利な食品探し」が出来る空間です。
                <br />
                不便だった食品探しも、Tastyモールなら「便利な食品探し」の空間になります。
              </p>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={LpPointPhoto03} alt="" />
            </dt>
            <dd>
              <img src={LpPointNum3} alt="" />
              <h3 className="style-h2">地域の特産品や珍しい食品に出会える</h3>
              <p>
                これまでネット販売することが困難だった食品が、Tastyモールには多く並んでいます。
                ある日立ち寄った道の駅、お土産店、あのお店で見かけた商品など、「また買いたかった」「旅行で行ったっきり見つからなかった」そんな再会の瞬間をTastyモールで。
              </p>
            </dd>
          </dl>
        </div>
      </section>
      <section className="lp-section lp-carousel type2 lp-fade">
        <h2 className="style-h2 text-center white style-h2-line">
          <div className="style-line">
            <img src={LpCtaLineL} alt="" />
          </div>
          自分だけのお気に入りの食品を
          <br />
          Tastyモールで見つけよう！！
          <div className="style-line">
            <img src={LpCtaLineR} alt="" />
          </div>
        </h2>
        <div className="scroll-infinity">
          <div className="scroll-infinity__wrap">
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
            </ul>
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
            </ul>
          </div>
          <div className="lp-content">
            <p className="lp-notice white">※写真はイメージになります。</p>
          </div>
        </div>
        <p className="style-h3 text-center white">Tastyモールでお買い物はこちら</p>
        <a
          className="lp-cta-btn lp-cta-btn-center lp-cta-btn-type2"
          href="https://tasty-mall.com/pre-signup"
        >
          無料で新規会員登録
        </a>
      </section>
      <section className="lp-section lp-security lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center style-border">
            Tastyモールの
            <br />
            ショップや商品は本当に安全なの？
          </h2>
          <ul>
            <li>
              <dl>
                <dt>
                  <img src={IllustPeopleFile} />
                </dt>
                <dd>
                  <p>
                    Tastyモールは
                    <span className="marker">
                      食品販売の許可された食品事業者のみが出店しています。
                    </span>
                    <br />
                    厳しい出店審査を経て出店しているので安心してお買い物いただけます。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <img src={IllustSearchFile} />
                </dt>
                <dd>
                  <p>
                    出品商品を
                    <span className="marker">運営管理者が全ショップ・全商品を１つ１つ審査</span>
                    し、商品の販売資格があるかを都度確認しています。
                    不正出品を回避し、高品質の商品だけが並ぶように努めています。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <img src={IllustDocumentFile} />
                </dt>
                <dd>
                  <p>
                    輸入食品の出店者は販売代理店を前提に出店審査しているため、並行輸入食品の取り扱いはなく
                    <span className="marker">
                      厳格な日本規格に適合された商品のみを扱っております。
                    </span>
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
      <section className="lp-section lp-shop lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center style-border">出店ショップご紹介</h2>
          <ul>
            <li>
              <dl>
                <dt>
                  <img src={LpShopKonosore} />
                </dt>
                <dd>
                  <h3 className="style-h3">対馬コノソレ</h3>
                  <p>
                    オーガニック、自然食の店【コノソレnatural
                    factory】は、からだに優しい、安心・安全にこだわった自然派食品を提供しています。
                    からだに優しく、安心・安全にこだわった自然派食品と手作り雑貨などをお取り寄せできるお店です。
                    化学肥料不使用、無農薬で育てた「対州そば青汁」をはじめ、原木しいたけや非加熱の和蜂のはちみつ、無添加の季節のジャムなど、健康や美容を気づかうあなたへ、長崎県の国境の島・対馬（つしま）からいのちの源をお届けします。
                  </p>
                  <a href="https://tasty-mall.com/shop/42">
                    <SvgCheck />
                    商品一覧はこちら
                  </a>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <img src={LpShopSaitaya} />
                </dt>
                <dd>
                  <h3 className="style-h3">咲多家（さいたや）</h3>
                  <p>
                    咲多家は全国の「こだわりの食品」を取り扱う東京都小金井市にある小さな食品卸会社です。
                    京都府、滋賀県をはじめとする全国各地の隠れた銘店・老舗の味に優れたこだわりの食品を仕入れて、食にこだわるお客様が喜ぶよう、ギフトセットなどにしてお届けいたします。
                    歴史・実績・信頼を積み重ね、まもなく創業50年を迎えます。
                  </p>
                  <a href="https://tasty-mall.com/shop/45">
                    <SvgCheck />
                    商品一覧はこちら
                  </a>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <img src={LpShopTougane} />
                </dt>
                <dd>
                  <h3 className="style-h3">道の駅　みのりの郷東金</h3>
                  <p>
                    『みのりの郷東金』は、東金市緑花木センター跡地に新鮮な農産物をはじめとする特産物の直売や地元の食材を使ったレストラン、植木のほ場、市民サークル活動の発表の場としてのイベント広場を兼ね備えた産業交流拠点施設です。東金市近郊の朝採れ野菜に旬の果物、そして手造りの加工品、今一番おいしく召し上がれるものばかりを取り揃えています。
                  </p>
                  <a href="https://tasty-mall.com/shop/29">
                    <SvgCheck />
                    商品一覧はこちら
                  </a>
                </dd>
              </dl>
            </li>
          </ul>
          <a className="lp-cta-btn lp-cta-btn-center" href="https://tasty-mall.com/pre-signup">
            無料で新規会員登録
          </a>
        </div>
      </section>
      <section className="lp-section lp-voice lp-fade">
        <h2 className="style-h2 text-center">
          <span className="en orange">VOICE</span>
          <br />
          ご利用者の声
        </h2>
        <div className="lp-content">
          <div className="voice-list">
            <div className="voice-list__item">
              <dl>
                <dt>
                  <img src={LpVoicePhoto40sWoman} alt="" />
                  <span>40代女性</span>
                </dt>
                <dd>
                  <p>
                    地域の特産品などは旅行など遠出した際にまとめて購入していましたが、ここでは珍しい地域の特産品が多数掲載されてるので、遠くまで買いに行かなくても購入できるのが嬉しい。今後もより多くの美味しい日本の特産品や色々な種類が増えていくのも楽しみにしています。
                  </p>
                </dd>
              </dl>
            </div>
            <div className="voice-list__item">
              <dl>
                <dt>
                  <img src={LpVoicePhoto20sWoman} alt="" />
                  <span>20代女性</span>
                </dt>
                <dd>
                  <p>
                    アレルギーを持っているので、自分好みの好きな食品を探すのが大変でしたが、こだわり検索でアレルギーを除外して検索できるので今まで苦労していた食品探しが簡単にできています！自分だけでなく、ギフトを探すときにも安心して使うことができそうです。
                  </p>
                </dd>
              </dl>
            </div>
            <div className="voice-list__item">
              <dl>
                <dt>
                  <img src={LpVoicePhoto30sMan} alt="" />
                  <span>30代男性</span>
                </dt>
                <dd>
                  <p>
                    多くのサイトが世に溢れかえってる中、食品にのみ特化したサイトにとても魅力を感じました。また、検索機能も豊富で、食にこだわりのある人が欲しい条件が揃っていると感じました。ギフトや会社の付き合い等で食品を贈ることが多いので、Tastyモールを使ってお祝い物やちょっとしたプレゼントを購入するのに使いたいと思います。
                  </p>
                </dd>
              </dl>
            </div>
            <div className="voice-list__item">
              <dl>
                <dt>
                  <img src={LpVoicePhoto30sWoman} alt="" />
                  <span>30代女性</span>
                </dt>
                <dd>
                  <p>
                    オーガニック・無添加の食品を探すために今まで実際店舗に訪れ購入しに行ってましたが、そういった商品がオンラインでも購入できるようになって嬉しいです。珍しい食品も多いので「人と被らないギフト探し」にも利用させていただいてます。物珍しい商品が多いので新商品がアップされるたびにワクワクして見ています。
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <section className="lp-section lp-delivery lp-fade">
        <div className="lp-content">
          <h2 className="style-h2 text-center style-border">配送について</h2>
          <ul>
            <li>
              <h3 className="style-h3 white">送料はどのくらいかかるの？</h3>
              <dl>
                <dt>
                  <img src={IllustTrackFile} />
                </dt>
                <dd>
                  <p>Tastyモールでは各ショップごとにそれぞれ送料が設定されています。</p>
                  <p>
                    例：山田太郎さんのお買い物
                    <br />
                    Aショップ　商品金額 5,000円＋送料1,000円
                    <br />
                    Bショップ　商品金額 5,000円＋送料1,500円
                    <br />
                    お買い物合計金額： 12,500円
                  </p>
                  <p className="lp-delivery-text">
                    Tastyモールでは、小さな食品事業者の商品や海外の輸入食品も出品されています。各ショップの設定している送料にご理解いただきますようお願いいたします。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <h3 className="style-h3 white">配送日時指定はできる？</h3>
              <dl>
                <dt className="illust_time wide">
                  <SvgIllustTime />
                </dt>
                <dd className="wide">
                  <p>
                    出店しているショップや商品によっては、指定できない場合もございます。
                    <br />
                    Tastyモールに関するお問い合わせは、下記のフォームよりお願いいたします。
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
      <section className="lp-section lp-carousel type2 lp-fade">
        <h2 className="style-h2 text-center white style-h2-line">
          <div className="style-line">
            <img src={LpCtaLineL} alt="" />
          </div>
          自分だけのお気に入りの食品を
          <br />
          Tastyモールで見つけよう！！
          <div className="style-line">
            <img src={LpCtaLineR} alt="" />
          </div>
        </h2>
        <div className="scroll-infinity">
          <div className="scroll-infinity__wrap">
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
            </ul>
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
            </ul>
          </div>
          <div className="scroll-infinity__wrap scroll-infinity-type2 sp">
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
            </ul>
            <ul className="scroll-infinity__list scroll-infinity__list--left">
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto05} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto04} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto03} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto02} />
              </li>
              <li className="scroll-infinity__item">
                <img src={LpCtaPhoto01} />
              </li>
            </ul>
          </div>
          <div className="lp-content">
            <p className="lp-notice white">※写真はイメージになります。</p>
          </div>
        </div>

        <p className="style-h3 text-center white">Tastyモールでお買い物はこちら</p>
        <a
          className="lp-cta-btn lp-cta-btn-center lp-cta-btn-type2"
          href="https://tasty-mall.com/pre-signup"
        >
          無料で新規会員登録
        </a>
      </section>
    </div>
  );
};
