import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Center } from '@components/layout/center';
import { ForBeginner } from '@features/lp/ForBeginner';

import '@features/lp/assets/css/root.css';
import '@features/lp/assets/css/style.css';
import '@features/lp/assets/css/slick.css';
import '@features/lp/assets/css/slick-theme.css';

const Lp = () => {
  return (
    <Layout mb={0} pt={0} pb={0}>
      <Helmet>
        <title>はじめての方へ | Tastyモール</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="description"
          content="あなたの「美味しい」に気づく、見つかる。Find your Tasty。Tastyモールはあなた好みにパーソナライズされた食品をマッチング。日本全国の素晴らしい・美味しい・珍しい食品が揃うフードマーケットです。大切な人への食品ギフトにも「あなたのこだわり」を。"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <link rel="icon" type="image/x-icon" href="https://tasty-mall.com/images/favicon.ico" />
        <meta name="og:image" content="https://tasty-mall.com/images/customer-ogp.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="はじめての方へ | Tastyモール" />
        <meta name="twitter:image" content="https://tasty-mall.com/images/customer-ogp.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link href="https://tasty-mall.com/styles.0c50ec80cb10715bdcb0.css" rel="stylesheet" />
      </Helmet>
      <Suspense fallback={<Center>Loading...</Center>}>
        <ForBeginner />
      </Suspense>
    </Layout>
  );
};

export default Lp;
