import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIllustTime = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={96} height={125} fill="none" {...props}>
    <g clipPath="url(#illust_time_svg__a)">
      <g clipPath="url(#illust_time_svg__b)">
        <path
          fill="#F39800"
          d="M79.826 112.078c16.544-16.544 16.544-43.367 0-59.91-16.544-16.545-43.368-16.545-59.912 0-16.544 16.543-16.544 43.366 0 59.91 16.544 16.545 43.368 16.545 59.912 0Z"
        />
        <path
          fill="#fff"
          d="M49.873 116.695c19.094 0 34.572-15.478 34.572-34.572S68.967 47.55 49.873 47.55 15.3 63.03 15.3 82.123s15.479 34.572 34.573 34.572Z"
        />
        <path
          fill="#CE7A00"
          d="M43.414 56.152a.938.938 0 0 1-1.13-.684l-1.062-4.303a.938.938 0 0 1 .684-1.13.938.938 0 0 1 1.13.684l1.062 4.303a.938.938 0 0 1-.684 1.13ZM57.84 114.211a.939.939 0 0 1-1.13-.684l-1.062-4.303a.938.938 0 0 1 .684-1.13.937.937 0 0 1 1.13.684l1.062 4.303a.938.938 0 0 1-.684 1.13ZM75.848 75.664a.938.938 0 0 1 .684-1.13l4.303-1.062a.938.938 0 0 1 1.13.684.938.938 0 0 1-.684 1.13l-4.303 1.062a.938.938 0 0 1-1.13-.684ZM17.79 90.09a.938.938 0 0 1 .684-1.13l4.302-1.062a.938.938 0 0 1 1.13.684.938.938 0 0 1-.684 1.13l-4.303 1.062a.938.938 0 0 1-1.13-.684ZM64.29 58.173a.935.935 0 0 1-.316-1.283l1.166-1.93a.935.935 0 0 1 1.598.968l-1.166 1.93a.935.935 0 0 1-1.282.315ZM33.309 109.638a.936.936 0 0 1-.315-1.283l1.445-2.39a.935.935 0 0 1 1.598.967l-1.445 2.39a.935.935 0 0 1-1.283.316ZM73.832 96.567a.935.935 0 0 1 1.283-.315l1.93 1.165c.442.266.581.842.316 1.283a.935.935 0 0 1-1.283.315l-1.931-1.165a.935.935 0 0 1-.315-1.283ZM22.367 65.586a.935.935 0 0 1 1.283-.315l2.39 1.445a.935.935 0 0 1-.968 1.598l-2.39-1.445a.935.935 0 0 1-.315-1.283ZM49.963 81.88c-.297.486-.928.639-1.414.346l-15.276-9.28a1.031 1.031 0 0 1-.347-1.414c.297-.486.927-.64 1.414-.347l15.276 9.281c.486.297.64.928.347 1.414Z"
        />
        <path
          fill="#CE7A00"
          d="M50.089 83.162a1.031 1.031 0 0 1-.347-1.413l7.756-12.765c.297-.486.927-.639 1.413-.346.486.297.639.927.346 1.413l-7.755 12.765c-.297.486-.927.639-1.413.346Z"
        />
        <path
          fill="#F39800"
          d="M53.5 81.231a3.733 3.733 0 0 1-2.731 4.52 3.733 3.733 0 0 1-4.52-2.733 3.733 3.733 0 0 1 2.732-4.519 3.733 3.733 0 0 1 4.52 2.733Z"
        />
      </g>
      <path
        fill="#F9CC80"
        d="M50.663 23.625c.025-1.8.23-3.113.618-3.938.388-.825 1.119-1.725 2.194-2.7 1.05-.95 1.856-1.83 2.419-2.643.562-.813.843-1.681.843-2.606 0-1.125-.375-2.063-1.125-2.813s-1.8-1.125-3.15-1.125c-1.3 0-2.3.369-3 1.106-.7.738-1.212 1.506-1.537 2.306l-3.15-1.387c.55-1.475 1.481-2.731 2.794-3.769C48.88 5.02 50.513 4.5 52.462 4.5c2.5 0 4.425.694 5.775 2.081 1.35 1.388 2.026 3.056 2.026 5.006 0 1.2-.257 2.288-.77 3.263-.512.975-1.33 2-2.456 3.075-1.224 1.175-1.962 2.075-2.212 2.7-.25.625-.388 1.625-.413 3h-3.75Zm1.8 9.375a2.53 2.53 0 0 1-1.857-.769 2.53 2.53 0 0 1-.769-1.856c0-.725.257-1.344.77-1.856a2.53 2.53 0 0 1 1.855-.769 2.53 2.53 0 0 1 1.857.769 2.53 2.53 0 0 1 .768 1.856 2.53 2.53 0 0 1-.768 1.856 2.53 2.53 0 0 1-1.857.769Z"
      />
      <path
        fill="#F9CC80"
        stroke="#FBC98F"
        strokeWidth={0.75}
        d="m9.072 39.753.266.265.265-.265L18 31.355l8.397 8.398.266.265.265-.265 1.575-1.575.265-.265-.265-.265-8.398-8.398 8.398-8.397.265-.265-.265-.265-1.575-1.576-.265-.265-.266.265L18 27.145l-8.397-8.398-.265-.265-.266.265-1.575 1.576-.265.265.265.265 8.398 8.397-8.398 8.398-.265.265.265.265 1.575 1.575Z"
      />
      <circle cx={84.75} cy={30} r={9.75} stroke="#F9CC80" strokeWidth={3} />
    </g>
    <defs>
      <clipPath id="illust_time_svg__a">
        <path fill="#fff" d="M0 0h96v124.5H0z" />
      </clipPath>
      <clipPath id="illust_time_svg__b">
        <path fill="#fff" d="M7.5 39.75h84.75v84.75H7.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIllustTime;
